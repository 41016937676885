import axios from 'axios';
import { clientSideFilter, convertPagination, getAxiosConfig } from '../common';
import { SENTINEL_SERVER_URL } from '../../config';

const API_URL = `${SENTINEL_SERVER_URL}/api/1`;

const verifications = async (from, to, pagination, filters) => {
  const {
    sessionId,
    streamId,
    userId,
    userIdType,
    ...clientSideFilters
  } = filters;
  const { limit, offset } = convertPagination(pagination);
  const filterFn = async (currentOffset) => {
    const config = {
      ...getAxiosConfig(),
      params: {
        from,
        to,
        limit,
        offset: currentOffset,
        sessionId,
        streamId,
        userId,
        userIdType,
      },
    };
    const response = await axios
      .get(
        `${API_URL}/verifications`,
        config,
      );
    return response.data;
  };
  return clientSideFilter(filterFn, clientSideFilters, limit, offset);
};

const incidents = async (from, to, pagination, filters) => {
  const { limit, offset } = convertPagination(pagination);
  const filterFn = async (currentOffset) => {
    const config = {
      ...getAxiosConfig(),
      params: {
        from,
        to,
        limit,
        offset: currentOffset,
      },
    };
    const result = await axios
      .get(
        `${API_URL}/incidents`,
        config,
      );

    const { data } = result;

    data.result = await Promise.all(data.result.map(async (item) => {
      let firstVerification;
      let lastVerification;
      const firstVerifications = await verifications(
        item.createdAt - 10000,
        item.createdAt + 1000,
        { pageSize: 1, pageIndex: 0 },
        { verificationId: item.firstVerificationId },
      );

      if (firstVerifications.result.length > 0) {
        [firstVerification] = firstVerifications.result;

        const lastVerifications = await verifications(
          item.createdAt - 1000,
          to,
          { pageSize: 1, pageIndex: 0 },
          { sessionId: firstVerification.sessionId, status: 'succeeded' },
        );

        if (lastVerifications.result.length > 0) {
          [lastVerification] = lastVerifications.result;
        }
      }

      return {
        ...item,
        streamId: firstVerification?.steamId,
        userId: firstVerification?.userId,
        userIdType: firstVerification?.userIdType,
        lastVerificationOutcome: lastVerification?.outcome,
        lastVerificationCreatedAt: lastVerification?.createdAt,
      };
    }));
    return data;
  };

  return clientSideFilter(filterFn, filters, limit, offset);
};

const services = {
  incidents,
  verifications,
};

export default services;
