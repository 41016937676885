import { useState, useEffect } from 'react';

import { DARK_MODE_STORAGE_KEY } from '@/constants';

import { Button } from '@/components/ui/button';

function ThemeSwitcher() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDarkMode = localStorage.getItem(DARK_MODE_STORAGE_KEY) === 'true';
    setDarkMode(isDarkMode);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem(DARK_MODE_STORAGE_KEY, darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Button
      onClick={toggleDarkMode}
      className="btn mr-4"
      type="button"
    >
      {darkMode ? 'Light Mode' : 'Dark Mode'}
    </Button>
  );
}

export default ThemeSwitcher;
