import { formatDate } from '@/utils';
import { t } from 'i18next';

import { Input } from '@/components/ui/input';

import DataTableColumnHeader from
  '@/components/DataTable/DataTableColumnHeader';

const MetadataColumns = [
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t('sessions.metaName')} />
    ),
    cell: ({ row }) => (
      <div className="flex items-center">
        {row.getValue('name')}
      </div>
    ),
    size: 300,
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <Input
        placeholder={t('sessions.filterByMetaName')}
        value={(table.getColumn('name')?.getFilterValue()) ?? ''}
        onChange={(event) => table
          .getColumn('name')?.setFilterValue(event.target.value)}
        className="h-8 w-[150px] lg:w-[250px]"
      />
    ),
  },
  {
    accessorKey: 'eventTimestamp',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('sessions.eventTimestamp')}
      />
    ),
    size: 300,
    cell: ({ row }) => (
      <div className="flex items-center">
        {formatDate(row.getValue('eventTimestamp'))}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
];

export default MetadataColumns;
