import { Link } from 'react-router-dom';
import { t } from 'i18next';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="container flex py-5">
        <div className="w-1/2">
          <Link to="/privacy" target="_blank" className="mr-4">
            {t('footer.privacyPolicy')}
          </Link>
        </div>
        <div className="w-1/2 flex justify-end">
          <p>
            {`© ${year}, `}
            <a
              href="https://www.cursorinsight.com/"
              target="_blank"
              rel="noreferrer"
            >
              Cursor Insight Ltd.
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
