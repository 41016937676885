import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isCurrentUserAuthenticated, isCurrentUserInRole } from './utils';

function RedirectIfNotAuthenticated({ role, children }) {
  const location = useLocation();

  if (!isCurrentUserAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (role !== undefined && !isCurrentUserInRole(role)) {
    return <Navigate to="/access_denied" state={{ from: location }} />;
  }

  return children;
}

RedirectIfNotAuthenticated.propTypes = {
  role: PropTypes.string,
  children: PropTypes.node.isRequired,
};

RedirectIfNotAuthenticated.defaultProps = {
  role: undefined,
};

export default RedirectIfNotAuthenticated;
