// eslint-disable-next-line import/prefer-default-export
const formatDate = (timestamp) => {
  if (Number.isFinite(timestamp)) {
    if (timestamp > 9999999999999) {
      return new Date(timestamp / 1000).toISOString();
    }
    return new Date(timestamp).toISOString();
  }
  return null;
};

const parseDate = (dateStr) => new Date(dateStr).getTime();

export {
  formatDate,
  parseDate,
};
