import PropTypes from 'prop-types';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretSortIcon,
} from '@radix-ui/react-icons';
import { t } from 'i18next';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

export default function DataTableColumnHeader({
  column,
  title,
  className,
}) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  const getSortedIcon = (col) => {
    switch (col.getIsSorted()) {
      case 'desc':
        return (<ArrowDownIcon className="ml-2 h-4 w-4" />);
      case 'asc':
        return (<ArrowUpIcon className="ml-2 h-4 w-4" />);
      default:
        return (<CaretSortIcon className="ml-2 h-4 w-4" />);
    }
  };

  return (
    <div className={cn('items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="-ml-3 h-8 data-[state=open]:bg-accent"
          >
            <div style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              {title}
            </div>
            {getSortedIcon(column)}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
            <ArrowUpIcon
              className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
            />
            {t('table.asc')}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
            <ArrowDownIcon
              className="mr-2 h-3.5 w-3.5 text-muted-foreground/70"
            />
            {t('table.desc')}
          </DropdownMenuItem>
          <DropdownMenuSeparator />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

DataTableColumnHeader.propTypes = {
  className: PropTypes.string,
  column: PropTypes.shape({
    getCanSort: PropTypes.func,
    toggleSorting: PropTypes.func,
  }),
  title: PropTypes.string,
};

DataTableColumnHeader.defaultProps = {
  className: '',
  column: {
    getCanSort: () => false,
    toggleSorting: () => {},
    toggleVisibility: () => {},
  },
  title: '',
};
