import { sha256 } from 'js-sha256';
import { User } from 'oidc-client-ts';
import { ROLE_ADMIN } from '@/constants';
import { OIDC_AUTHORITY, OIDC_CLIENT_ID } from '@/config';

const getUser = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`,
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

const isCurrentUserAuthenticated = () => {
  const user = getUser();
  return user !== null && !user.expired;
};

const isCurrentUserInRole = (role) => {
  const user = getUser();
  return user !== null && !user.expired
    && user?.profile?.client_roles?.includes(role);
};

// TODO: this is a temporary client side solution
// In the future this should be done on the server
const getPrivacyFilter = () => {
  if (isCurrentUserInRole(ROLE_ADMIN)) {
    return {};
  }

  const user = getUser();

  return {
    userId: sha256(user?.profile.email),
    userIdType: 'SHA-256',
  };
};

export {
  isCurrentUserAuthenticated,
  isCurrentUserInRole,
  getPrivacyFilter,
  getUser,
};
