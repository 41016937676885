import { t } from 'i18next';
import { Link } from 'react-router-dom';

import { formatDate } from '@/utils';
import { Input } from '@/components/ui/input';

import DataTableColumnHeader from
  '@/components/DataTable/DataTableColumnHeader';
import DataTableFacetedFilter from
  '@/components/DataTable/DataTableFacetedFilter';
import { cn } from '@/lib/utils';

import { getLabel, outcomes } from '../session/VerificationColumns';

const columns = [
  {
    accessorKey: 'incidentId',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('incidents.incidentId')}
      />
    ),
    size: 300,
    cell: ({ row }) => (
      <div className="flex items-center">
        {row.getValue('incidentId')}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <Input
        placeholder={t('incidents.filterByIncidentId')}
        value={(table.getColumn('incidentId')?.getFilterValue()) ?? ''}
        onChange={(event) => table
          .getColumn('incidentId')?.setFilterValue(event.target.value)}
        className="h-8 w-[150px] lg:w-[250px]"
      />
    ),
  },
  {
    accessorKey: 'sessionId',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t('incidents.sessionId')} />
    ),
    size: 300,
    cell: ({ row }) => (
      <div className="flex items-center">
        <Link
          to={`/sessions/${row.getValue('sessionId')}`}
          className="text-link transition-colors hover:text-foreground"
        >
          {row.getValue('sessionId')}
        </Link>
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <Input
        placeholder={t('incidents.filterBySessionId')}
        value={(table.getColumn('sessionId')?.getFilterValue()) ?? ''}
        onChange={(event) => table
          .getColumn('sessionId')?.setFilterValue(event.target.value)}
        className="h-8 w-[150px] lg:w-[250px]"
      />
    ),
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t('incidents.createdAt')} />
    ),
    size: 250,
    cell: ({ row }) => (
      <div className="flex items-center">
        {formatDate(row.getValue('createdAt'))}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: 'lastVerificationCreatedAt',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('incidents.lastVerificationCreatedAt')}
      />
    ),
    size: 250,
    cell: ({ row }) => (
      <div className="flex items-center">
        {formatDate(row.getValue('lastVerificationCreatedAt'))}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: 'lastVerificationOutcome',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('incidents.lastVerificationOutcome')}
      />
    ),
    size: 200,
    cell: ({ row }) => {
      const outcome = outcomes.find((o) => o.value
        === row.getValue('lastVerificationOutcome'));
      if (!outcome) {
        return null;
      }
      return (
        <div className="flex w-[100px] items-center">
          {outcome.icon && (
            <outcome.icon
              className={cn(
                'mr-2 h-4 w-4',
                outcome.style ?? 'text-muted-foreground',
              )}
            />
          )}
          <span>{getLabel(outcome.value)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <DataTableFacetedFilter
        column={table.getColumn('lastVerificationOutcome')}
        title={t('incidents.lastVerificationOutcome')}
        options={outcomes.map((outcome) => ({
          ...outcome,
          label: getLabel(outcome.value),
        }))}
      />
    ),
  },
];

export default columns;
