import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@/components/ui/button';

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

import logo from '@/images/logo.svg';
import logoDark from '@/images/logo-dark.svg';

function AccessDeniedPage() {
  const { t } = useTranslation();

  return (
    <main className="h-screen flex justify-center items-center">
      <Card className="w-[400px]">
        <CardHeader>
          <CardTitle className="text-center">
            <img
              src={logo}
              alt="Graboxy Sentinel"
              width={350}
              className="mr-5 mb-5 dark:hidden"
            />
            <img
              src={logoDark}
              alt="Graboxy Sentinel"
              width={350}
              className="mr-5 mb-5 hidden dark:block"
            />
            {t('accessDenied.title')}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {t('accessDenied.content')}
        </CardContent>
        <CardFooter>
          <Link to="/">
            <Button>{t('accessDenied.back')}</Button>
          </Link>
        </CardFooter>
      </Card>
    </main>
  );
}

export default AccessDeniedPage;
