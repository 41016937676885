import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
} from '@/components/ui/card';

import apiKeyService from '../../services/client/apiKey';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

function ApiKeys() {
  const { t } = useTranslation();

  const defaultErrorMessage = t('apiKeys.defaultErrorMessage');

  const [apiKeys, setApiKeys] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchData = async () => {
    const apiKeysResult = await apiKeyService.listKeys();
    setApiKeys(apiKeysResult);
  };

  const deleteApiKey = async (apiKeyId) => {
    setErrorMessage(null);
    try {
      await apiKeyService.deleteKey(apiKeyId);
      await fetchData();
    } catch {
      setErrorMessage(defaultErrorMessage);
    }
  };

  const generateApiKey = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    try {
      await apiKeyService.createKey();
      await fetchData();
    } catch {
      setErrorMessage(defaultErrorMessage);
    }
  };

  useEffect(() => {
    setErrorMessage(null);
    try {
      fetchData();
    } catch {
      setErrorMessage(defaultErrorMessage);
    }
  }, [setApiKeys, defaultErrorMessage]);

  return (
    <>
      <Header />
      <section className="apikeys">
        <div className="container">
          {errorMessage && (
            <div className="errorMessage">
              {errorMessage}
            </div>
          )}
          <div className="flex justify-between">
            <div className="mb-5">
              <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight
            lg:text-5xl"
              >
                Api
              </h1>
            </div>
            <div className="my-5">
              <Button onClick={generateApiKey} className="btn">
                {t('apiKeys.generateNewKey')}
              </Button>
            </div>
          </div>
          <div className="flex flex-wrap gap-x-16 gap-y-6">
            {apiKeys.map((apiKey) => (
              <div className="api-row" key={apiKey.id}>
                <Card className="w-[400px]">
                  <CardContent>
                    <div className="pt-4">
                      <h2
                        className="mt-10 scroll-m-20 border-b pb-2 text-3xl
                        font-semibold tracking-tight transition-colors
                        first:mt-0"
                      >
                        {apiKey.name}
                      </h2>
                      <p className="my-5">{apiKey.value}</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => deleteApiKey(apiKey.id)}
                        className="btn"
                      >
                        {t('apiKeys.delete')}
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ApiKeys;
