import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './styles/index.scss';

import RedirectIfNotAuthenticated
  from './components/Auth/RedirectIfNotAuthenticated';

import AccessDeniedPage from './pages/AccessDeniedPage';
import IndexPage from './pages/IndexPage';
import LoginPage from './pages/LoginPage';
import Sessions from './pages/sessions/Sessions';
import SessionPage from './pages/session/SessionPage';
import StreamPage from './pages/stream/StreamPage';
import Integration from './pages/integration/Integration';
import ApiKeys from './pages/apiKeys/ApiKeys';
import Profile from './pages/profile/Profile';
import Incidents from './pages/incidents/Incidents';
import { ROLE_ADMIN } from './constants';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" exact element={<LoginPage />} />
        <Route
          exact
          path="/sessions"
          element={(
            <RedirectIfNotAuthenticated>
              <Sessions />
            </RedirectIfNotAuthenticated>
          )}
        />
        <Route
          exact
          path="/sessions/:sessionId"
          element={(
            <RedirectIfNotAuthenticated>
              <SessionPage />
            </RedirectIfNotAuthenticated>
          )}
        />
        <Route
          exact
          path="/sessions/:sessionId/:streamId"
          element={(
            <RedirectIfNotAuthenticated>
              <StreamPage />
            </RedirectIfNotAuthenticated>
          )}
        />
        <Route
          exact
          path="/incidents"
          element={(
            <RedirectIfNotAuthenticated>
              <Incidents />
            </RedirectIfNotAuthenticated>
          )}
        />
        <Route
          exact
          path="/integration"
          element={(
            <RedirectIfNotAuthenticated role={ROLE_ADMIN}>
              <Integration />
            </RedirectIfNotAuthenticated>
          )}
        />
        <Route
          exact
          path="/api-keys"
          element={(
            <RedirectIfNotAuthenticated role={ROLE_ADMIN}>
              <ApiKeys />
            </RedirectIfNotAuthenticated>
          )}
        />
        <Route
          exact
          path="/profile"
          element={(
            <RedirectIfNotAuthenticated>
              <Profile />
            </RedirectIfNotAuthenticated>
          )}
        />
        <Route path="/access_denied" exact element={<AccessDeniedPage />} />
        <Route path="*" element={<IndexPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
