import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import {
  Card,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

import logo from '@/images/logo.svg';
import logoDark from '@/images/logo-dark.svg';

function IndexPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  }, [auth, navigate]);

  return (
    <main className="h-screen flex justify-center items-center">
      <Card className="w-[400px] justify-center">
        <CardHeader className="text-center">
          <CardTitle>
            <img
              src={logo}
              alt="Graboxy Sentinel"
              width={350}
              className="mr-5 mb-5 dark:hidden"
            />
            <img
              src={logoDark}
              alt="Graboxy Sentinel"
              width={350}
              className="mr-5 mb-5 hidden dark:block"
            />
            {t('index.header')}
          </CardTitle>
        </CardHeader>
      </Card>
    </main>
  );
}

export default IndexPage;
