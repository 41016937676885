import axios from 'axios';
import { BASE_URL, getAxiosConfig } from '../common';

const MOCK_RESPONSE = true;
const API_URL = `${BASE_URL}/client/api-keys`;

let MOCK_DATA = [{
  id: 1,
  name: 'First key',
  value: 'first-value',
}, {
  id: 2,
  name: 'Second key',
  value: 'second-value',
}];

const listKeys = () => {
  if (MOCK_RESPONSE) {
    return new Promise((resolve) => {
      resolve([...MOCK_DATA]);
    });
  }

  return axios
    .get(
      API_URL,
      getAxiosConfig(),
    )
    .then((response) => response.data);
};

const createKey = () => {
  if (MOCK_RESPONSE) {
    return new Promise((resolve) => {
      const id = Math.max(0, ...MOCK_DATA.map((item) => item.id)) + 1;
      MOCK_DATA.push({
        id,
        name: Math.random().toString(36),
        value: Math.random().toString(36),
      });
      resolve();
    });
  }

  return axios
    .post(
      API_URL,
      null,
      getAxiosConfig(),
    )
    .then((response) => response.data);
};

const deleteKey = (id) => {
  if (MOCK_RESPONSE) {
    return new Promise((resolve) => {
      MOCK_DATA = MOCK_DATA.filter((item) => item.id !== id);
      resolve();
    });
  }

  return axios
    .delete(
      `${API_URL}/${id}`,
      getAxiosConfig,
    )
    .then((response) => response.data);
};

const services = {
  listKeys,
  createKey,
  deleteKey,
};

export default services;
