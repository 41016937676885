import PropTypes from 'prop-types';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { t } from 'i18next';
import PeriodicRefresh from '../PeriodicRefresh';

export default function DataTableToolbar({ table, updateFn }) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {table.getAllColumns()?.map((column) => {
          const { columnDef } = column;
          if (columnDef.filterUI) {
            return columnDef.filterUI(table);
          }
          return null;
        })}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            {t('table.reset')}
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      {updateFn !== undefined && (
        <PeriodicRefresh updateFn={updateFn} />
      )}
    </div>
  );
}

DataTableToolbar.propTypes = {
  table: PropTypes.shape({
    getAllColumns: PropTypes.func,
    getState: PropTypes.func,
    resetColumnFilters: PropTypes.func,
  }).isRequired,
  updateFn: PropTypes.func,
};

DataTableToolbar.defaultProps = {
  updateFn: undefined,
};
