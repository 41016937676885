const {
  API_KEY_NAME,
  API_KEY_VALUE,
  GTM_CONTAINER_ID,
  OIDC_AUTHORITY,
  OIDC_CLIENT_ID,
  OIDC_CLIENT_SECRET,
  SENTINEL_SERVER_URL,
  TRAP_SERVER_URL,
// eslint-disable-next-line no-underscore-dangle
} = window.__RUNTIME_CONFIG__;

export {
  API_KEY_NAME,
  API_KEY_VALUE,
  GTM_CONTAINER_ID,
  OIDC_AUTHORITY,
  OIDC_CLIENT_ID,
  OIDC_CLIENT_SECRET,
  SENTINEL_SERVER_URL,
  TRAP_SERVER_URL,
};
