import { useState, useCallback } from 'react';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from '@/components/ui/breadcrumb';
import DataTable from '@/components/DataTable/DataTable';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';

import { INCIDENT_LIMIT } from '@/constants';

import sentinelService from '@/services/client/sentinel';
import { getPrivacyFilter } from '@/components/Auth/utils';

import columns from './IncidentColumns';

function Incidents() {
  const [to] = useState(DateTime.now());
  const [errorMessage, setErrorMessage] = useState('');

  const queryFn = useCallback(async ({ pageParam = 0 }) => {
    try {
      setErrorMessage('');
      return await sentinelService.incidents(
        0,
        to.toMillis(),
        { pageIndex: pageParam, pageSize: INCIDENT_LIMIT },
        getPrivacyFilter(),
      );
    } catch {
      setErrorMessage(t('incidents.fetchIncidentsFailed'));
    }
    return { result: [] };
  }, [to]);

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t('incidents.title')}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          {errorMessage && (
            <div className="errorMessage">
              {errorMessage}
            </div>
          )}
          <DataTable
            columns={columns}
            queryFn={queryFn}
            queryKey="incidents"
          />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Incidents;
