const DARK_MODE_STORAGE_KEY = 'darkMode';

const INCIDENT_LIMIT = 10;
const VERIFICATION_RESULT_LIMIT = 20;

const SESSION_LIMIT = 10;
const STREAM_LIMIT = 10;
const METADATA_LIMIT = 10;
const STREAM_DATA_LIMIT = 20;
const ROLE_ADMIN = 'admin';

const METADATA_EVENT_TYPE = 11;
const MOUSE_EVENT_TYPE = 0;

const WINDOWS_DESKTOP_DISPLAY = 'Windows Desktop';

const NO_DATA_VERIFICATION_OUTCOME = 'noData';

export {
  DARK_MODE_STORAGE_KEY,
  INCIDENT_LIMIT,
  VERIFICATION_RESULT_LIMIT,
  SESSION_LIMIT,
  STREAM_LIMIT,
  METADATA_LIMIT,
  STREAM_DATA_LIMIT,
  ROLE_ADMIN,
  METADATA_EVENT_TYPE,
  MOUSE_EVENT_TYPE,
  WINDOWS_DESKTOP_DISPLAY,
  NO_DATA_VERIFICATION_OUTCOME,
};
