import axios from 'axios';
import { clientSideFilter, convertPagination, getAxiosConfig } from '../common';
import { TRAP_SERVER_URL } from '../../config';
import sentinelService from './sentinel';
import {
  METADATA_EVENT_TYPE,
  MOUSE_EVENT_TYPE,
  NO_DATA_VERIFICATION_OUTCOME,
  WINDOWS_DESKTOP_DISPLAY,
} from '../../constants';

const API_URL = `${TRAP_SERVER_URL}/api/1`;

const streams = (filters) => {
  const config = {
    ...getAxiosConfig(),
    params: {
      ...filters,
    },
  };

  return axios
    .get(
      `${API_URL}/streams`,
      config,
    )
    .then((response) => response.data);
};

const getInfoFromMeta = (meta) => {
  let metadata = '';
  if (Object.keys(meta).length === 1 && Object.keys(meta)[0] === 'custom') {
    metadata = WINDOWS_DESKTOP_DISPLAY;
  } else {
    const site = meta.location?.current?.split('/', 3).pop();
    metadata = `${site} (${meta.platform?.description})`;
  }
  const uid = meta.custom?.uid;
  const email = meta.custom?.emailAddress;

  return {
    metadata,
    userId: uid?.value,
    userIdType: uid?.type,
    email,
  };
};

const getEvent = (events) => {
  if (events.streams && events.streams.length > 0
    && events.streams[0].events.length > 0) {
    return events.streams[0].events[0];
  }
  return undefined;
};

const getSpecificEventsFromStream = async (
  sessionId,
  streamId,
  userId,
  userIdType,
) => {
  const lastMouseEvents = await streams({
    sessionId,
    streamId,
    userId,
    userIdType,
    eventCount: 1,
    eventType: MOUSE_EVENT_TYPE,
  });

  const metadataEvents = await streams({
    sessionId,
    streamId,
    userId,
    userIdType,
    eventCount: 1,
    eventType: METADATA_EVENT_TYPE,
  });

  const lastMouseEvent = getEvent(lastMouseEvents);
  const metadataEvent = getEvent(metadataEvents);
  return { lastMouseEvent, metadataEvent };
};

const sessions = async (pagination, filters) => {
  const { limit, offset } = convertPagination(pagination);
  const { userId, userIdType, ...clientSideFilters } = filters;
  const filterFn = async (currentOffset) => {
    const config = {
      ...getAxiosConfig(),
      params: {
        limit,
        offset: currentOffset,
        userId,
        userIdType,
      },
    };

    const result = await axios
      .get(
        `${API_URL}/sessions`,
        config,
      );

    const { data } = result;

    data.result = await Promise.all(data.result.map(async (item) => {
      const {
        lastMouseEvent,
        metadataEvent,
      } = await getSpecificEventsFromStream(
        item.sessionId,
        undefined,
        userId,
        userIdType,
      );
      let lastVerification;

      const verificationsBySession = await sentinelService.verifications(
        0,
        new Date().getTime(),
        { pageSize: 1, pageIndex: 0 },
        {
          sessionId: item.sessionId,
          userId,
          userIdType,
        },
      );
      if (verificationsBySession.result.length > 0) {
        [lastVerification] = verificationsBySession.result;
      }

      return {
        ...item,
        lastDataSubmittedAt: lastMouseEvent ? lastMouseEvent[1] : null,
        ...(metadataEvent ? getInfoFromMeta(metadataEvent[2]) : {}),
        lastVerificationOutcome: lastVerification?.outcome
          ?? NO_DATA_VERIFICATION_OUTCOME,
      };
    }));
    return data;
  };
  return clientSideFilter(filterFn, clientSideFilters, limit, offset);
};

const streamHeaders = async (sessionId, pagination, filters) => {
  const { userId, userIdType } = filters;
  const config = {
    ...getAxiosConfig(),
    params: {
      sessionId,
      ...convertPagination(pagination),
      userId,
      userIdType,
    },
  };

  const result = await axios
    .get(
      `${API_URL}/stream-headers`,
      config,
    );

  const { data } = result;

  data.result = await Promise.all(data.result.map(async (item) => {
    const {
      lastMouseEvent,
      metadataEvent,
    } = await getSpecificEventsFromStream(
      undefined,
      item.streamId,
      userId,
      userIdType,
    );
    let lastVerification;

    const verificationsBySession = await sentinelService.verifications(
      0,
      new Date().getTime(),
      { pageSize: 1, pageIndex: 0 },
      {
        sessionId: item.sessionId,
        userId,
        userIdType,
      },
    );
    if (verificationsBySession.result.length > 0) {
      [lastVerification] = verificationsBySession.result;
    }

    return {
      ...item,
      lastDataSubmittedAt: lastMouseEvent ? lastMouseEvent[1] : null,
      ...(metadataEvent ? getInfoFromMeta(metadataEvent[2]) : {}),
      lastVerificationOutcome: lastVerification?.outcome
        ?? NO_DATA_VERIFICATION_OUTCOME,
    };
  }));
  return data;
};

const metadata = (streamId, pagination) => {
  const config = {
    ...getAxiosConfig(),
    params: {
      streamId,
      ...convertPagination(pagination),
    },
  };

  return axios
    .get(
      `${API_URL}/stream-metadata`,
      config,
    )
    .then((response) => response.data);
};

const services = {
  sessions,
  streams,
  streamHeaders,
  metadata,
};

export default services;
