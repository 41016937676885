import { useState } from 'react';
import { t } from 'i18next';

import { SESSION_LIMIT } from '@/constants';

import trapService from '@/services/client/trap';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from '@/components/ui/breadcrumb';

import DataTable from '@/components/DataTable/DataTable';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import { getPrivacyFilter } from '@/components/Auth/utils';

import sessionColumns from './SessionColumns';

function Sessions() {
  const [errorMessage, setErrorMessage] = useState('');
  const queryFn = async ({ pageParam = 0 }) => {
    try {
      setErrorMessage('');
      return await trapService.sessions(
        { pageIndex: pageParam, pageSize: SESSION_LIMIT },
        getPrivacyFilter(),
      );
    } catch {
      setErrorMessage(t('sessions.fetchSessionsFailed'));
    }
    return { result: [] };
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>{t('streams.sessions')}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          {errorMessage && (
            <div className="errorMessage">
              {errorMessage}
            </div>
          )}
          <DataTable
            columns={sessionColumns}
            queryFn={queryFn}
            queryKey="sessions"
          />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Sessions;
