import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

function PeriodicRefresh(props) {
  const { updateFn } = props;

  const [enabled, setEnabled] = useState(true);
  const [delay, setDelay] = useState(60);
  const intervalId = useRef(null);

  const clearIntervalFn = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, [intervalId]);

  useEffect(() => {
    clearIntervalFn();
    if (enabled) {
      intervalId.current = setInterval(() => updateFn(), delay * 1000);
    }
    return () => clearIntervalFn();
  }, [enabled, delay, clearIntervalFn, updateFn]);

  return (
    <div className="flex items-center space-x-6 lg:space-x-8">
      <div className="flex items-center space-x-2">
        <p className="text-sm font-medium">{t('periodicRefresh.caption')}</p>
        <Select
          value={`${delay}`}
          onValueChange={(value) => {
            setDelay(Number(value));
          }}
        >
          <SelectTrigger className="h-8 w-[100px]">
            <SelectValue placeholder={`${delay}`} />
          </SelectTrigger>
          <SelectContent side="top">
            {[15, 30, 45, 60, 120].map((delayOption) => (
              <SelectItem key={delayOption} value={`${delayOption}`}>
                {`${delayOption} ${t('periodicRefresh.sec')}`}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          className="hidden p-2 lg:flex"
          onClick={() => setEnabled((prevValue) => !prevValue)}
        >
          <span>
            {enabled
              ? t('periodicRefresh.disable')
              : t('periodicRefresh.enable')}
          </span>
        </Button>

      </div>
    </div>
  );
}

PeriodicRefresh.propTypes = {
  updateFn: PropTypes.func.isRequired,
};

export default PeriodicRefresh;
