import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import {
  GTM_CONTAINER_ID,
  OIDC_AUTHORITY,
  OIDC_CLIENT_ID,
  OIDC_CLIENT_SECRET,
} from './config';

const tagManagerArgs = {
  gtmId: GTM_CONTAINER_ID,
};

const oidcUserStore = new WebStorageStateStore({ store: window.localStorage });
const oidcSigninCallback = () => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname,
  );
};

const queryClient = new QueryClient();

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <AuthProvider
      authority={OIDC_AUTHORITY}
      client_id={OIDC_CLIENT_ID}
      client_secret={OIDC_CLIENT_SECRET}
      onSigninCallback={oidcSigninCallback}
      userStore={oidcUserStore}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AuthProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
