import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';

import { Button } from '@/components/ui/button';

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from '@/components/ui/navigation-menu';

import logo from '@/images/logo.svg';
import logoDark from '@/images/logo-dark.svg';

import { ROLE_ADMIN } from '@/constants';

import ThemeSwitcher from '../ThemeSwitcher';
import RequireRole from '../Auth/RequireRole';

function Header() {
  const auth = useAuth();
  return (
    <header>
      <div className="container flex py-5">
        <div className="w-3/4 flex justify-start">
          <a href="/sessions">
            <img
              src={logoDark}
              alt="Graboxy Sentinel"
              width={150}
              className="mr-5 dark:hidden"
            />
            <img
              src={logo}
              alt="Graboxy Sentinel"
              width={150}
              className="mr-5 hidden dark:block"
            />
          </a>
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem className="pr-4">
                <Link to="/incidents">
                  {t('header.incidents')}
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem className="pr-4">
                <Link to="/sessions">
                  {t('header.sessions')}
                </Link>
              </NavigationMenuItem>
              <RequireRole role={ROLE_ADMIN}>
                <NavigationMenuItem className="pr-4">
                  <Link to="/api-keys">
                    {t('header.apiKeys')}
                  </Link>
                </NavigationMenuItem>
              </RequireRole>
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className="w-1/4 flex justify-end">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem className="pr-4">
                <Link to="/profile">
                  {t('header.profile')}
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <Button
            type="submit"
            className="btn mr-4"
            onClick={() => auth.signoutRedirect({
              post_logout_redirect_uri: window.location.href,
            })}
          >
            {t('header.logout')}
          </Button>
          <ThemeSwitcher />
        </div>
      </div>
    </header>
  );
}

export default Header;
