import { formatDate } from '@/utils';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import { Input } from '@/components/ui/input';
import TooltipCell from '@/components/DataTable/TooltipCell';
import DataTableColumnHeader from
  '@/components/DataTable/DataTableColumnHeader';
import DataTableFacetedFilter from
  '@/components/DataTable/DataTableFacetedFilter';

import { cn } from '@/lib/utils';
import { getLabel, outcomes } from './VerificationColumns';

const StreamColumns = (sessionId) => ([
  {
    accessorKey: 'streamId',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t('sessions.streamId')} />
    ),
    size: 330,
    cell: ({ row }) => (
      <div className="flex items-center">
        <Link
          to={`/sessions/${sessionId}/${row.getValue('streamId')}`}
          className="text-link transition-colors hover:text-foreground"
        >
          {row.getValue('streamId')}
        </Link>
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <Input
        placeholder={t('sessions.filterByStreamId')}
        value={(table.getColumn('streamId')?.getFilterValue()) ?? ''}
        onChange={(event) => table
          .getColumn('streamId')?.setFilterValue(event.target.value)}
        className="h-8 w-[150px] lg:w-[250px]"
      />
    ),
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t('sessions.createdAt')} />
    ),
    size: 220,
    cell: ({ row }) => (
      <div className="flex eventTypeitems-center">
        {formatDate(row.getValue('createdAt'))}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: 'lastDataSubmittedAt',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('sessions.lastDataSubmittedAt')}
      />
    ),
    size: 220,
    cell: ({ row }) => (
      <div className="flex items-center">
        {formatDate(row.getValue('lastDataSubmittedAt'))}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: 'email',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('sessions.email')}
      />
    ),
    size: 200,
    cell: ({ row }) => (
      <TooltipCell value={row.getValue('email')} />
    ),
    filterFn: (row, id, value) => row.getValue(id)?.startsWith(value) ?? false,
    filterUI: (table) => (
      <Input
        placeholder={t('sessions.filterByEmail')}
        value={(table.getColumn('email')?.getFilterValue()) ?? ''}
        onChange={(event) => table
          .getColumn('email')?.setFilterValue(event.target.value)}
        className="h-8 w-[180px] lg:w-[250px]"
      />
    ),

  },
  {
    accessorKey: 'metadata',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('sessions.metadata')}
      />
    ),
    size: 150,
    cell: ({ row }) => (
      <TooltipCell value={row.getValue('metadata')} />
    ),
    filterFn: (row, id, value) => row
      .getValue(id)?.toLowerCase().includes(value.toLowerCase()) ?? false,
    filterUI: (table) => (
      <Input
        placeholder={t('sessions.filterByMetadata')}
        value={(table.getColumn('metadata')?.getFilterValue()) ?? ''}
        onChange={(event) => table
          .getColumn('metadata')?.setFilterValue(event.target.value)}
        className="h-8 w-[180px] lg:w-[250px]"
      />
    ),
  },
  {
    accessorKey: 'lastVerificationOutcome',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('sessions.lastVerificationOutcome')}
      />
    ),
    size: 180,
    cell: ({ row }) => {
      const outcome = outcomes.find((o) => o.value
        === row.getValue('lastVerificationOutcome'));
      if (!outcome) {
        return null;
      }
      return (
        <div className="flex items-center">
          {outcome.icon && (
            <outcome.icon
              className={cn(
                'mr-2 h-4 w-4',
                outcome.style ?? 'text-muted-foreground',
              )}
            />
          )}
          <span>{getLabel(outcome.value)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <DataTableFacetedFilter
        column={table.getColumn('lastVerificationOutcome')}
        title={t('sessions.lastVerificationOutcome')}
        options={outcomes.map((outcome) => ({
          ...outcome,
          label: getLabel(outcome.value),
        }))}
      />
    ),
  },
]);

export default StreamColumns;
