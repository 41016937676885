import PropTypes from 'prop-types';
import { isCurrentUserAuthenticated, isCurrentUserInRole } from './utils';

function RequireRole({ role, children }) {
  if (!isCurrentUserAuthenticated()) {
    return null;
  }

  if (role !== undefined && !isCurrentUserInRole(role)) {
    return null;
  }

  return children;
}

RequireRole.propTypes = {
  role: PropTypes.string,
  children: PropTypes.node.isRequired,
};

RequireRole.defaultProps = {
  role: undefined,
};

export default RequireRole;
