import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { Slash } from 'lucide-react';

import { STREAM_LIMIT } from '@/constants';

import trapService from '@/services/client/trap';
import sentinelService from '@/services/client/sentinel';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs';

import DataTable from '@/components/DataTable/DataTable';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import { getPrivacyFilter } from '@/components/Auth/utils';

import { DateTime } from 'luxon';
import streamColumnsFn from './StreamColumns';
import verificationColumns from './VerificationColumns';

function SessionPage() {
  const { sessionId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [to] = useState(DateTime.now());

  const streamColumns = useMemo(() => streamColumnsFn(sessionId), [sessionId]);
  const streamsQueryFn = async ({ pageParam = 0 }) => {
    try {
      setErrorMessage('');
      return await trapService.streamHeaders(
        sessionId,
        { pageIndex: pageParam, pageSize: STREAM_LIMIT },
        getPrivacyFilter(),
      );
    } catch {
      setErrorMessage(t('streams.fetchStreamsFailed'));
    }
    return { result: [] };
  };

  const verificationsQueryFn = async ({ pageParam = 0 }) => {
    try {
      setErrorMessage('');
      return await sentinelService.verifications(
        0,
        to.toMillis(),
        { pageIndex: pageParam, pageSize: STREAM_LIMIT },
        {
          sessionId,
          ...getPrivacyFilter(),
        },
      );
    } catch {
      setErrorMessage(t('streams.fetchVerificationsFailed'));
    }
    return { result: [] };
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/sessions">
                  {t('sessionPage.sessions')}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator>
                <Slash />
              </BreadcrumbSeparator>
              <BreadcrumbItem>
                <BreadcrumbPage>{sessionId}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          {errorMessage && (
            <div className="errorMessage">
              {errorMessage}
            </div>
          )}
          <Tabs defaultValue="streams">
            <TabsList>
              <TabsTrigger value="streams">
                {t('sessionPage.streams')}
              </TabsTrigger>
              <TabsTrigger value="verifications">
                {t('sessionPage.verifications')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="streams">
              <DataTable
                columns={streamColumns}
                queryFn={streamsQueryFn}
                queryKey={`streams-${sessionId}`}
              />
            </TabsContent>
            <TabsContent value="verifications">
              <DataTable
                columns={verificationColumns}
                queryFn={verificationsQueryFn}
                queryKey={`verifications-${sessionId}`}
              />
            </TabsContent>
          </Tabs>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default SessionPage;
