import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Card,
  CardContent,
} from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

function ApiKeys() {
  const { t, i18n: { changeLanguage, language } } = useTranslation();

  const auth = useAuth();

  const { user } = auth;
  const { profile } = user;
  const { email, family_name: familyName, given_name: givenName } = profile;

  const languageChanged = (newValue) => {
    changeLanguage(newValue);
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <h1 className="scroll-m-20 text-4xl font-extrabold
                  tracking-tight lg:text-5xl"
          >
            Profile
          </h1>
          <Card className="w-2/4 mt-5 p-5">
            <CardContent>
              <form>
                <div className="mb-5">
                  <Label htmlFor="firstName">{t('profile.firstName')}</Label>
                  <Input
                    id="firstName"
                    name="firstName"
                    readOnly
                    value={givenName}
                  />
                </div>
                <div className="mb-5">
                  <Label htmlFor="lastName">{t('profile.lastName')}</Label>
                  <Input
                    id="lastName"
                    name="lastName"
                    readOnly
                    value={familyName}
                  />
                </div>
                <div className="mb-5">
                  <Label htmlFor="email">{t('profile.email')}</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    readOnly
                    value={email}
                  />
                </div>
                <div className="mb-5">
                  <Label htmlFor="language">{t('profile.language')}</Label>
                  <Select
                    id="language"
                    name="laguage"
                    defaultValue={language}
                    onValueChange={languageChanged}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="English" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="hu">Magyar</SelectItem>
                      <SelectItem value="en">English</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ApiKeys;
