import { API_KEY_NAME, API_KEY_VALUE } from '../config';

const BASE_URL = '/api/1';

const clientSideFilter = async (fetchFn, filters, limit, offset) => {
  const result = [];
  let hasNext = false;
  let currentOffset = offset;

  do {
    // eslint-disable-next-line no-await-in-loop
    const responseData = await fetchFn(currentOffset);
    result.push(
      ...responseData.result.filter((item) => Object.keys(filters)
        .every((key) => item[key] === filters[key])),
    );
    currentOffset += limit;
    hasNext = responseData.next !== undefined
      && responseData.result.length > 0;
  } while (result.length < limit && hasNext);

  return {
    result: result.slice(0, limit),
    next: hasNext ? true : undefined,
    offset: currentOffset,
  };
};

const convertPagination = (pagination) => ({
  limit: pagination.pageSize,
  offset: pagination.pageSize * pagination.pageIndex,
});

const getAxiosConfig = () => {
  const headers = {};

  if (typeof API_KEY_NAME === 'string' && API_KEY_NAME !== ''
    && API_KEY_NAME !== 'undefined' && typeof API_KEY_VALUE === 'string'
    && API_KEY_VALUE !== '' && API_KEY_VALUE !== 'undefined') {
    headers[API_KEY_NAME] = API_KEY_VALUE;
  }

  return ({
    headers,
    validateStatus(status) {
      return status >= 200 && status < 400;
    },
  });
};

export {
  BASE_URL,
  clientSideFilter,
  convertPagination,
  getAxiosConfig,
};
