import PropTypes from 'prop-types';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

export default function TooltipCell({ value }) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className="flex items-center whitespace-nowrap overflow-hidden
            text-ellipsis"
          >
            {value}
          </div>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          <p>{value}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

TooltipCell.propTypes = {
  value: PropTypes.string.isRequired,
};
