import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { Slash } from 'lucide-react';

import {
  METADATA_LIMIT,
  STREAM_DATA_LIMIT,
  VERIFICATION_RESULT_LIMIT,
} from '@/constants';

import trapService from '@/services/client/trap';
import sentinelService from '@/services/client/sentinel';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs';

import DataTable from '@/components/DataTable/DataTable';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import { getPrivacyFilter } from '@/components/Auth/utils';

import { DateTime } from 'luxon';
import MetadataColumns from './MetadataColumns';
import DataColumns from './DataColumns';
import VerificationColumns from '../session/VerificationColumns';

function StreamPage() {
  const { sessionId, streamId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [to] = useState(DateTime.now());
  const metadataQueryFn = async ({ pageParam = 0 }) => {
    try {
      setErrorMessage('');
      return await trapService.metadata(
        streamId,
        { pageIndex: pageParam, pageSize: METADATA_LIMIT },
        getPrivacyFilter(),
      );
    } catch {
      setErrorMessage(t('streams.fetchStreamsFailed'));
    }
    return { result: [] };
  };

  const verificationsQueryFn = async ({ pageParam = 0 }) => {
    try {
      setErrorMessage('');
      return await sentinelService.verifications(
        0,
        to.toMillis(),
        { pageIndex: pageParam, pageSize: VERIFICATION_RESULT_LIMIT },
        {
          streamId,
          ...getPrivacyFilter(),
        },
      );
    } catch (exception) {
      setErrorMessage(t('streams.fetchVerificationsFailed'));
    }
    return { result: [] };
  };

  const dataQueryFn = async ({ pageParam = 0 }) => {
    try {
      setErrorMessage('');
      const result = await trapService.streams({
        streamId,
        ...getPrivacyFilter(),
        eventCount: (pageParam + 1) * STREAM_DATA_LIMIT,
      });
      if (result.streams && result.streams.length === 1) {
        const pageData = result
          .streams[0]
          .events
          .slice(0, STREAM_DATA_LIMIT)
          .reverse()
          .map((evt) => ({
            eventType: evt[0],
            eventTimestamp: evt[1],
            data: JSON.stringify(evt.slice(2)),
          }));
        return {
          result: pageData,
          next: true,
        };
      }
    } catch (exception) {
      setErrorMessage(t('streams.fetchDataFailed'));
    }
    return { result: [] };
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/sessions">
                  {t('streamPage.sessions')}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator>
                <Slash />
              </BreadcrumbSeparator>
              <BreadcrumbItem>
                <BreadcrumbLink href={`/sessions/${sessionId}`}>
                  {sessionId}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator>
                <Slash />
              </BreadcrumbSeparator>
              <BreadcrumbItem>
                <BreadcrumbPage>{streamId}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          {errorMessage && (
            <div className="errorMessage">
              {errorMessage}
            </div>
          )}
          <Tabs defaultValue="metadata">
            <TabsList>
              <TabsTrigger value="metadata">
                {t('streamPage.metadata')}
              </TabsTrigger>
              <TabsTrigger value="data">
                {t('streamPage.data')}
              </TabsTrigger>
              <TabsTrigger value="verifications">
                {t('streamPage.verifications')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="metadata">
              <DataTable
                columns={MetadataColumns}
                queryFn={metadataQueryFn}
                queryKey={`metadata-${streamId}`}
              />
            </TabsContent>
            <TabsContent value="data">
              <DataTable
                columns={DataColumns}
                queryFn={dataQueryFn}
                queryKey={`data-${streamId}`}
              />
            </TabsContent>
            <TabsContent value="verifications">
              <DataTable
                columns={VerificationColumns}
                queryFn={verificationsQueryFn}
                queryKey={`verifications-${streamId}`}
              />
            </TabsContent>
          </Tabs>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default StreamPage;
