import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { useEffect } from 'react';

import logo from '@/images/logo.svg';
import logoDark from '@/images/logo-dark.svg';

function LoginPage() {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/sessions');
    }
  }, [auth, navigate]);

  const isLoginDisabled = () => auth.activeNavigator
    || auth.isLoading
    || auth.error;

  const getDescription = () => {
    switch (auth.activeNavigator) {
      case 'signinSilent':
        return t('login.signInInProgress');
      case 'signoutRedirect':
        return t('login.signOutInProgress');
      default:
        break;
    }

    if (auth.isLoading) {
      return t('login.authIsLoading');
    }

    if (auth.error) {
      return `${t('login.authError')} ${auth.error.message}`;
    }

    return t('login.performLogin');
  };

  return (
    <main className="h-screen flex justify-center items-center">
      <Card className="w-[400px] justify-center">
        <CardHeader className="text-center">
          <CardTitle>
            <img
              src={logoDark}
              alt="Graboxy Sentinel"
              width={200}
              className="mr-5 mb-5 dark:hidden"
            />
            <img
              src={logo}
              alt="Graboxy Sentinel"
              width={200}
              className="mr-5 mb-5 hidden dark:block"
            />
            {t('login.header')}
          </CardTitle>
          <CardDescription />
        </CardHeader>
        <CardContent>
          <form>
            <div className="grid w-full items-center gap-4">
              <div>{getDescription()}</div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button
            type="submit"
            className="btn mt-3"
            onClick={() => auth
              .signinRedirect({ redirect_uri: window.location.href })}
            disabled={isLoginDisabled()}
          >
            {t('login.login')}
          </Button>
        </CardFooter>
      </Card>
    </main>
  );
}

export default LoginPage;
